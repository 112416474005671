import React, { useEffect, useState } from 'react';
import CaseSlider from './CaseSlider'
import ButtonC from './ButtonC';
import quoteImg from '../img/quote.svg'
import axios from 'axios';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../App';
import epilogDiscovery from "../img/latest_news/epilogDiscovery.png"
import LatestNewsSlider from "./LatestNewsSlider";




const slideArrayFooter = [
    {
        subtitle: 'Eric, 57',
        description: 'Thank you for your help and understanding. Thank you.',
        caseSlideImg: quoteImg,
    },
    {
        subtitle: 'Catharina, 74',
        description: 'In the hospital since yesterday. Very ill. Confusing',
        caseSlideImg: quoteImg,
    },
    {
        subtitle: 'Gordon, 65',
        description: "I'm grateful for being able to take a walk twice a day in spite of the chemo effects. This enables me to tolerate the chemo much better.",
        caseSlideImg: quoteImg,
    },
    {
        subtitle: 'Margaret, 80',
        description: 'Struggling with breathing issues brought on by an infection. Thank you.',
        caseSlideImg: quoteImg,
    },
    {
        subtitle: 'Linda, 76',
        description: 'I cannot, have lost my voice - perm or temp, I have no idea.\n WhatsApp is my only means of communication at present.',
        caseSlideImg: quoteImg,
    },
    {
        subtitle: 'Walter, 82',
        description: 'I have just come out of hospital and am still coming to terms with being diagnosed as having COPD. Would like to speak to a counsellor about dealing with this emotionally and physically, if this is possible.',
        caseSlideImg: quoteImg,
    },
    {
        subtitle: 'Peter, 64',
        description: 'Prepared meals are planned each week on the strict instructions of my children, it helps!',
        caseSlideImg: quoteImg,
    },
    {
        subtitle: 'Ann, 78',
        description: 'Hi, I do feel that the fatigue has improved. No need to talk to a specialist at the moment. Thank you!',
        caseSlideImg: quoteImg,
    },
];
const intialState = {
    email_secrett: 'qd2d23asdiuh23r!',
    message: '',
    name: '',
    email: '',
}
const FooterU = (props) => {
    const latestNewsSlides =  [
        {
            title: 'Epilog and Discovery Health Revolutionize Care for Managing Complex Medical Conditions',
            description: 'Discovery is partnering with Epilog, a pioneering digital platform designed specifically to help individuals manage these complex journeys',
            caseSlideImg: epilogDiscovery,
            buttonText: "Read Article",
            buttonAction: ()=>{props.setNewsPage(true)}
        },

    ];
    const [submitIcon, setSubmitIcon] = useState(false);
    const [formData, setFormData] = useState(intialState);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    useEffect(() => {
        if (submitIcon) {
            setTimeout(() => {
                setSubmitIcon(false)
            }, 20000)
        }
    }, [submitIcon])

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Send a POST request using Axios
            const response = await axios.post('https://dev-api-member.epilog.world/website_send_email', formData);
            if (response.status === 200) {
                setSubmitIcon(true);
                setFormData(intialState);
                // Log a Firebase event when the form is successfully submitted
                logEvent(analytics, 'contact_us_submit_button', {
                    name: 'contact_us_submit_button',
                    purpose: 'perform_action'
                });
            }

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    };
    return (
        <div>
            <div className={"container"} id={"latestNews"} style={window.innerWidth>500 ? {
                marginBottom:"50px",width:"55%"
            } : {}}>
                <LatestNewsSlider title={"Latest News"} dataArr={latestNewsSlides} event_name={"navbar_latest_news_"}/>
            </div>
            <div className='footerU'>
                <div className='container'>
                    <CaseSlider title="Live feedback from our users" dataArrFooter={slideArrayFooter}
                                eventName="testimonials_"/>
                    <form id='contactUS'>
                        <h1>Contact Us</h1>
                        <div className='formGroup w50'>
                            <label>Name</label>
                            <input className='form-control' type='text' name='name'
                                   value={formData.name}
                                   onChange={handleChange}/>
                        </div>
                        <div className='formGroup w50'>
                            <label>Email</label>
                            <input className='form-control' type='email' name='email'
                                   value={formData.email}
                                   onChange={handleChange}/>
                        </div>
                        <div className='formGroup'>
                            <label>Message</label>
                            <textarea className='form-control' name='message'
                                      value={formData.message}
                                      onChange={handleChange}></textarea>
                        </div>
                        <ButtonC btnTxt="Submit" btnBgClr="#78F2A2" btnClr="#000" btnHeight="44px" className="submitBtn"
                                 submitIcon={submitIcon} onClick={handleSubmit}/>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default FooterU
